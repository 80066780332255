<template>
    <tr v-show="!item.isEdit" @click="openEditLine(index)">
        <td class="pl-10">
            <div class="col">
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.description }}
            </div>
        </td>

        <td class="text-center">{{ item.quantity }}</td>
        <td class="text-center">{{ item.rate }}</td>
        <td class="text-center">{{ getSubTotal.toFixed(4) }}</td>
        <td class="text-center">{{ item.discount_percent ?? 0 }}%</td>
        <td class="text-center">{{ getDiscountAmount.toFixed(4) }}</td>
        <td class="text-center">{{ getAmountAfterDiscount.toFixed(4) }}</td>
        <td class="text-center">{{ item.vat }}%</td>
        <td class="text-center">{{ getVatAmount.toFixed(4) }}</td>
        <td class="text-center">{{ getTotal.toFixed(4) }}</td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-outline-danger btn-sm">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

    <tr v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow'">
        <td>
            <div class="td-inline" v-if="type === 'pharmacy'">
                <AsyncSelect
                    placeholder="Select Product"
                    v-model="selectedProduct"
                    label="text"
                    :api-service="fetchProductList"
                    :format-label="option => option.text"
                    :option-selected="productSelected"
                    ref="productField"
                />
            </div>
            <div class="td-inline" v-else>
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.quantity"
                    type="number"
                    class="form-control"
                    placeholder="Qty"
                    :min="0"
                    style="min-width: 60px"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.rate"
                    type="number"
                    class="form-control"
                    placeholder="Rate"
                    :min="0"
                    style="min-width: 60px"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                {{ getSubTotal }}
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.discount_percent"
                    type="number"
                    class="form-control"
                    placeholder="Discount(%)"
                    :min="0"
                    :max="100"
                    @input="setDiscountPrice"
                    style="min-width: 60px"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    :value="item.discount_amount"
                    type="number"
                    class="form-control"
                    placeholder="Discount Amount"
                    :min="0"
                    style="min-width: 60px"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                {{ getAmountAfterDiscount }}
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.vat"
                    type="number"
                    class="form-control"
                    placeholder="Vat(%)"
                    :min="0"
                    :max="100"
                    @input="setVatAmount"
                    style="min-width: 60px"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                {{ getVatAmount }}
            </div>
        </td>
        <td>
            <div class="td-inline">
                {{ getTotal }}
            </div>
        </td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-outline-danger btn-sm">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

</template>
<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {formatNumberToTwoDecimals} from '@/services/utils/global';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchase from "@/services/modules/purchase";

const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    index: {
        type: Number
    },
    vats: {
        type: Array,
        default: []
    },
    type: {
        type: String,
        default: 'pharmacy'
    }
})
const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);

const {fetchProductList} = handlePurchase()

const selectedProduct = ref(null);


const openEditLine = (i) => {
    emit('onOpenEditItem', i)
}

const deleteItem = (i) => {
    emit('onDeleteItem', i)
}

const getSubTotal = computed(() => {
    return (props.item.quantity * props.item.rate)
})

const getDiscountAmount = computed(() => {
    return props.item.discount_amount;
})

const getAmountAfterDiscount = computed(() => {
    const total = getSubTotal.value;
    return total - props.item.discount_amount;

})

const getVatAmount = computed(() => {
    return props.item.vat_amount;
})

const getTotal = computed(() => {
    const total = getSubTotal.value - getDiscountAmount.value + getVatAmount.value;
    props.item.sub_total = getSubTotal.value;
    props.item.discount_amount = getDiscountAmount.value;
    props.item.vat_amount = getVatAmount.value;
    props.item.total = total;
    return total;
})

const setDiscountPrice = (e) => {
    let discountPercent = e.target.value;
    let amount = (getSubTotal.value * discountPercent) / 100;
    props.item.discount_amount = parseFloat(amount.toFixed(4))
}

const setVatAmount = (e) => {
    let vatPercent = e.target.value;
    let amount = (getAmountAfterDiscount.value * vatPercent) / 100;
    props.item.vat_amount = parseFloat(amount.toFixed(4))
}

const productSelected = () => {
    const selectedProductDesc = selectedProduct.value ? selectedProduct.value.description : null;
    console.log(selectedProductDesc)
    if (selectedProductDesc) {
        props.item.rate = selectedProductDesc.sales_price;
        props.item.description = selectedProductDesc.unit ? selectedProductDesc.unit.description : null;
        return;
    }

    props.item.rate = 0;
}

watch(selectedProduct, (newValue) => {
    if (newValue) {
        props.item.product_id = newValue.id;
        props.item.name = newValue.text;
    }
});

onMounted(() => {
    if (props.item.product_id) {
        selectedProduct.value = {
            id: props.item.product_id,
            text: props.item.name,
        };
    }
})

</script>